import Spline from '@splinetool/react-spline';
import { useState } from 'react';
import {IoMenu,IoCodeWorking} from 'react-icons/io5';
import {IoLogoHtml5,IoLogoCss3,IoLogoReact,IoLogoJavascript, IoLogoNodejs,  IoLogoLaravel,IoLogoAngular, IoLogoLinkedin, IoLogoGithub, IoLogoFacebook, IoLogoInstagram, IoLogoBitbucket } from "react-icons/io5";
import kaps from './kaps.jpg';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function App() {

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="flex w-screen min-h-screen flex-col items-center justify-center relative bg-primary pb-20"> 
       <nav className="w-full px-6 z-50 fixed inset-x-0 top-2 flex justify-center items-center">
        <div className="w-full md:w-880 bg-navBar p-4 rounded-2xl flex items-center">
          <p className="text-lg text-slate-200 font-medium">L.K. Portfolio</p>
          <div className="hidden md:flex items-center gap-6 ml-6 flex-1">
            <a href="#home" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out">
              Home
            </a>
            <a href="#about" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              About
            </a>
            <a href="#projects" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              Work-History
            </a>
            <a href="#education" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              Education
            </a>
            <a href="#contact" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              Contact
            </a>
          </div>
          <div className="block md:hidden ml-auto cursor-pointer" 
              onClick={() => setIsActive(!isActive)}>
            <IoMenu className="text-2x1 text-textBase " />
          </div>
          {isActive && (
            <div className="p-4 w-275 bg-navBar rounded-lg fixed top-24 right-16 flex flex-col items-center justify-evenly gap-6">
              <a href="#home" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out">
              Home
            </a>
            <a href="#about" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              About
            </a>
            <a href="#projects" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              Work-History
            </a>
            <a href="#education" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              Education
            </a>
            <a href="#contact" className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out" >
              Contact
            </a>
            </div>
          )}
        </div>
       </nav>
        
      <div className="w-screen h-screen" id="home">
        <Spline scene="https://prod.spline.design/cC0BvcMOLPP8VVl1/scene.splinecode" />  
      </div>
    {/*Main Sections*/}
    <main className="w-{80%} mt-5">
      {/*About Section*/}
        <section className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-24" id="about">
          <div className="w-full h-420 flex items-center justify-center">
            <div className="w-275 h-340 bg-white rounded-md relative">
              <img className="w-full h-full absolute -right-4 top-4 object-cover rounded-lg shadow-lg" src={kaps} alt=""/>
            </div>
          </div>
          <div className="w-full h-420 flex flex-col items-center justify-center">
            <p className="text-lg text-white text-center">
              Hi. My name is Lasha Kapanadze. I am a web developer. I have been working in this field for 2 years. I am currently working at PSP - Pharmacy as a web developer. I am currently learning ReactJS, Angular, NodeJs, Express, NestJS technologies. I am currently building a project on React and NestJS, I use MongoDB on the database side. I am also learning React Native.
              </p>


          </div>
          </section>    
        {/*workhistory*/}
          <p className="bg-white flex justify-center" id="projects">Work history</p>
        <section className="w-full flex items-center justify-center" >
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#1a202c', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1a202c' }}
              date="Sept 2022 — Present"
              iconStyle={{ background: '#1a202c', color: '#fff' }}
              icon={<IoCodeWorking className="text-2x1 text-textBase " />}>
                <h1>PSP - Pharmacy</h1>
                <h3>Web Developer</h3>
                <p>I am currently working as a web developer at PSP. I develop and add new
                  functionality to the existing internal program of the company. The main job is
                  PHP MySql and MsSql. I am also learning ReactJS, Angular, NodeJs, Express,
                  NestJS technologies. I am currently building a project on React and NestJS,
                  I use MongoDB on the database side</p>

            </VerticalTimelineElement> 
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#1a202c', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1a202c' }}
              date="Oct 2021 — Aug 2022"
              iconStyle={{ background: '#1a202c', color: '#fff' }}
              icon={<IoCodeWorking className="text-2x1 text-textBase " />}>
                <h1>TDG Consulting - Callapp</h1>
                <h3>Junior Developer</h3>
                <p>I started working in the mentioned company as a junior programmer, I was a FullStack
                  developer.
                  We actively used HTML, CSS, Javascript, ReactJS, PHP, Laravel, Linux Server,
                  Asterisk, MySQL.
                  Activity: The company had its own product in which it was
                  Unified call center management services: live panel, dashboard,
                  reports, etc.</p>

            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#1a202c', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1a202c' }}
              date="Jan 2018 — Present"
              iconStyle={{ background: '#1a202c', color: '#fff' }}
              icon={<IoCodeWorking className="text-2x1 text-textBase " />}>
                <h1>Georgian Gas Transportation Company</h1>
                <h3>Specialist of IT-Department</h3>
                <p>At the beginning of the second semester of the first year of university, I started 6 months
                Internship at the Georgian Gas Transportation Company. Then I passed
                3 months trial period and I started working in the IT department.
                I studied the basics of system administration and programming. touch
                I had C# and Java languages. Next I started to need for Web Development
                Learning languages such as: HTML, CSS, Javascript, PHP, as well as BASIC
                Administration (MYSQL, MSSQL). I am currently working in the mentioned company
                remotely.</p>

            </VerticalTimelineElement>
          </VerticalTimeline>
        </section>

         {/*education*/}
         <p className="bg-white flex justify-center" id="education">Education</p>
        <section className="w-full flex items-center justify-center" >
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#1a202c', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1a202c' }}
              date="Sept 2021 — Present"
              iconStyle={{ background: '#1a202c', color: '#fff' }}
              icon={<IoCodeWorking className="text-2x1 text-textBase " />}>
                <p>BTU - Business and Technologies University Georgia</p>
                <p>Masters Degree - MBA</p>

            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#1a202c', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1a202c' }}
              date="Sept 2017 — June 2021"
              iconStyle={{ background: '#1a202c', color: '#fff' }}
              icon={<IoCodeWorking className="text-2x1 text-textBase " />}>
                <p>BTU - Business and Technologies University Georgia</p>
                <p>Bachelors Degree-Informational Technologies</p>

            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#1a202c', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1a202c' }}
              date="Sept 2011 — June 2017"
              iconStyle={{ background: '#1a202c', color: '#fff' }}
              icon={<IoCodeWorking className="text-2x1 text-textBase " />}>
                <p>Physics and mathematics school N199 named after V. Komarov</p>
                <p>High School</p>

            </VerticalTimelineElement> 
          </VerticalTimeline>
        </section>

        {/*skills*/}
        <section className="flex flex-wrap items-center justify-evenly my-24 gap-4">
            <div className="border border-zinc-800 rounded-md p-2 min-w-[27px] md:max-w-[500px] hover:border-zinc-600 duration-100 ease-in-out">
                <p className="text-lg text-textBase font-medium uppercase">
                  My Skills
                </p>
                <div className="flex justify-center flex-row gap-3"> 
                  
                  <IoLogoHtml5 className="text-2x1 text-white skillButtons" title="HTML5"></IoLogoHtml5>
                  <IoLogoCss3 className="text-2x1 text-white skillButtons" title="CSS3"></IoLogoCss3>
                  <IoLogoJavascript className="text-2x1 text-white skillButtons" title="Javascript"></IoLogoJavascript>
                  <IoLogoReact className="text-2x1 text-white skillButtons" title="ReactJS"></IoLogoReact>
                  <IoLogoAngular className="text-2x1 text-white skillButtons" title="Angular"></IoLogoAngular>
                  <IoLogoNodejs className="text-2x1 text-white skillButtons" title="NodeJS"></IoLogoNodejs>
                  <IoLogoLaravel className="text-2x1 text-white skillButtons" title="Laravel"></IoLogoLaravel>

                </div> 
            </div>
        </section>

        {/*contact*/}
        <section className="flex flex-wrap items-center justify-evenly my-24 gap-4 h-200 w-screen" id="contact">
            
            <div className="border border-zinc-800 rounded-md p-2 min-w-[27px] md:max-w-[500px] hover:border-zinc-600 duration-100 ease-in-out" id="contactContainer">
                <p className="text-lg text-textBase font-medium uppercase">
                  Contact
                </p>
                <div className="flex justify-center flex-row gap-5">
                  <a href="https://www.linkedin.com/in/lasha-kapanadze-427544179/" target="_blank" rel="noreferrer" className="contactButtons">
                    <IoLogoLinkedin className="text-3x1 text-white"></IoLogoLinkedin>
                  </a>
                  <a href="https://github.com/kapsona777" target="_blank" rel="noreferrer" className="contactButtons">
                    <IoLogoGithub className="text-3x1 text-white"></IoLogoGithub>
                  </a>
                  <a href="https://bitbucket.org/kapex777/" target="_blank" rel="noreferrer" className="contactButtons">
                    <IoLogoBitbucket className="text-3x1 text-white"></IoLogoBitbucket>
                  </a> 
                  <a href="https://www.instagram.com/lashakapannadze/" target="_blank" rel="noreferrer" className="contactButtons">
                    <IoLogoInstagram className="text-3x1 text-white"></IoLogoInstagram>
                  </a>
                </div>
            </div>
        </section>

    </main>
      
    </div>
  );
  
}

export default App;
